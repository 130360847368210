/*
                                             ``..
                                     .:;;;:.`
                                `::::.
                             .;::,                          `.,:::::,.`
                          `::::                    `.::::::::::::::::::::;,
                        `;::,            `.,:;;::::::::::::;;:,.````,:::::::::
                       ;:::         ,;::::::::::::;::.`                  :::::::
                     ,:::        .;::;::;::,.`                             `:::::,
                    ;::;        :::::                                        ,::::;
                   :::;        :::::      `                                   `:::::
                  ::::        .:::::::::::::::.  `.,:;;::::;.                   ::::;
                 `:::,         :::::::::::::::::::::::::::::::                  ,::::`
                 ;:::              `      :::::::;:,`   ,:::::,       `.,:;::    ;:::;
                 ::::                   `;:::::::       `;::::`,:;;::::::::;     :::::
                `::::            .,:;:::::::::;:,       ::::::::::::::::,`       `::::
                `::::       ,:::::::::::;. ;::::      ::::::::;,`                 ::::
                 ::::      ;::::;:,.       ::::::::::::::::::`                   .::::
                 :::::    ``              ,::::::::::;,`::::::.,::::;:,          ;:::
                 `::::.                   ;::::.`       :::::::::::::::;         ::;:
                  ;::::`                  :::::          .:::::,` ,:::::        ::::
                   :::::.                ,:::;                    .::::.       ;:::
                    ,::::;               ;:.                 `.:;::::;        ::;:
                      ;::::;`                       .,::::::::::::::        .::;`
                       `:::::::,          `.:;;:::::::::::::;;:,`         `:::,
                          ,;:::::::::;:::::::::::::;:,.`                `;::,
                              ,:::::::::::;:,`                        :::;`
                                                                  .::::`
                                                             .::::,`
                                                     :;;;;:,.`
                                     Infinite Retail Power.                          */

// core frameworks
import angular from "angular";
import * as $ from "jquery";

// 1st party scripts
import "../js/Shared/shared.js";
import "../js/app-webui-core.js";

// 3rd party scripts
import * as moment from "moment";
import chosenJs from "chosen-js";
import retina from "retina.js";
import "../Scripts/modernizr.js";
import jQueryDateTimePicker from "jquery-datetimepicker";
import mouseWheel from "jquery-mousewheel";
import * as dateFunctions from "date-functions";

// angular extensions
import angularAnimate from "angular-animate";
import angularRoute from "angular-route";
import ngSanitize from "angular-sanitize";
import angularResource from "angular-resource";

// directives
import "../Scripts/text-angular.min.js";

// css assets 
import "../Content/site-webui-core.min.css";
import "../Content/analytics.css"
import "normalize.css/normalize.css";
import "../Content/styles/header-responsive.min.css";