'use strict';

angular.module('portalApp', ['ngRoute', 'ngResource', 'shared', 'ngAnimate','spsui.breadCrumbs', 'textAngular'])
    .provider('$env', ['Environment', function (environment) {

        /*
         * Added this provider to enable us to share the portal's server name and make it easier to maintain. 
         * The provider is the only module that can be loaded during the config phase, so it was chosen over Constants.
         * According to angular docs "You should use the Provider recipe only when you want to expose an API for application-wide configuration that must be made before the application starts."
         */

        let portalUrl;
        if (environment) {
            // actual portal running in actual environment based on environment parameter
            portalUrl = (environment === 'prod')
                ? "//analytics.spscommerce.com/"
                : "//" + environment + ".analytics.spscommerce.com/";
        } else {
            // local testing as environment parameter is empty (local testing has this appsetting as empty string)
            portalUrl = "//localhost:9002/";
        }

        this.getUrl = function () {
            return portalUrl;
        };

        this.$get = ['$window', function ($window) {
            return {
                getUrl: function () {
                    return portalUrl;
                },
                getUrlParameters: function () {
                    var params = {};

                    var queryString = $window.location.search.split('?')[1];

                    //if there is no query string, return an empty object
                    if (!queryString) {
                        return {};
                    }

                    var paramArray = queryString.split('&');

                    for (var i = 0; i < paramArray.length; i++) {
                        var keyValue = paramArray[i].split('=');
                        params[keyValue[0]] = decodeURIComponent(keyValue[1]);
                    }

                    return params;
                }
            };
        }];
    }])
    .config(['$httpProvider', '$sceDelegateProvider', '$locationProvider', '$envProvider', '$provide', function ($httpProvider, $sceDelegateProvider, $locationProvider, $envProvider, $provide) {

        // Signals to the server that the messages received from angular are AJAX requests
        $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
        // Tells angular to be utilize cross domain calls
        $httpProvider.defaults.useXDomain = true;
        // IE9 needs this turned off so that it doesn't cache every request
        $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';

        // forces http provider to provide credentials on cross domain request
        $httpProvider.defaults.withCredentials = true;

        //adding sps commerce sub domains to whitelist so we can load them from different sub domains
        $sceDelegateProvider.resourceUrlWhitelist([$envProvider.getUrl() + '**', 'https:' + $envProvider.getUrl() + '**', 'http:' + $envProvider.getUrl() + '**']);

        //configuring the location provider to use html5 mode to enable us to use parameters
        //This is now set to False due to BI-1575. 
        $locationProvider.html5Mode(false);

        //customizing text angular toolbar
        $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions){
            // $delegate is the taOptions we are decorating

            // add the button to the default toolbar definition
            taOptions.toolbar = [
                ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
                ['bold', 'italics', 'underline'],
                ['ul', 'ol', 'justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
                ['insertImage', 'insertLink'],
                ['redo', 'undo', 'clear']
            ];
            return taOptions;
        }]);
    }]);
